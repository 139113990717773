import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import MetaTags from 'react-meta-tags';

import * as ui from '../utilities/ui';

import BlockQuote from '../components/blocks/block-quote';
import BlockCooperations from '../components/blocks/block-cooperations';

const MyCooperations = inject("stores") (
    observer (
        class MyCooperations extends React.Component {

            constructor(props) {
                super(props);
                this.state = {
                    slug: ''
                };

                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
            }

            componentDidMount = async () => {
                ui.handleScroll('top');

                const route = this.props.match.path;
                const slug = route.substring(1);

                if (this.storeContent.content_pages.length === 0) {
                    await this.storeContent.getContentPages('pages', 100);
                }

                const page =  this.storeContent.content_pages.find(page => page.slug === slug);
                console.log('SbC page 1',page)

                let content = await this.storeContent.getContentPage(page.id);
                let seo = content.yoast_head_json;
                let acf = content.acf;

                this.setState({
                    route: route,
                    content: content,
                    slug: slug,
                    acf: acf,
                    seo: seo
                })
            }

            componentDidUpdate = async (prevProps, prevState) => {
                ui.handleScroll('top');
                let content;
                let pageblock;
                let pageblocks = [];

                const route = this.props.match.path;
                let slug = route.substring(1);

                if (prevState.route !== route) {
                    let page = this.storeContent.content_pages.find(page => page.slug === slug);
                    content = await this.storeContent.getContentPage(page.id);
                    const pageQuote = content.BlockQuote;
                    const bgPageQuote = content.BgBlockQuote;

                    this.setState({
                        route: route,
                        content: content,
                        slug: slug,
                        pageQuote: pageQuote,
                        bgPageQuote: bgPageQuote
                    })
                }
            }

            gotoPage = (e, page) => {
                let url = '';

                if (page === 'meeting') {
                    url = '/kennismakingsgesprek';
                }

                if (page === 'about') {
                    url = '/over-charles-ruiters';
                }

                this.props.history.push(url);
            }

            render() {
                return (
                    <React.Fragment>
                        <MetaTags>
                            <title>{this.state.seo && this.state.seo.og_title}</title>
                            <meta name="description" content={this.state.seo && this.state.seo.og_description} />
                            <meta property="og:title" content={this.state.seo && this.state.seo.og_title} />
                        </MetaTags>
                        
                        <BlockQuote
                            pageQuote = {this.state.pageQuote}
                            bgPageQuote = {this.state.bgPageQuote}
                        />

                        <div className="wrapper--section">
                            <div className="page--content">
                                <div className="wrapper-content--text">
                                    {this.state.content &&
                                        <div className="wp-page-content wp-page-content--start">
                                            <h1>{this.state.content && this.state.content.title.rendered}</h1>
                                            <p dangerouslySetInnerHTML={{ __html: this.state.content.content.rendered}} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="form-row form-row--section form-row--buttons">
                            <button 
                                className={"btn btn--primary btn--alternative " + this.state.btn_class} 
                                onClick={(e) => this.gotoPage(e, 'meeting')}
                                dangerouslySetInnerHTML={{ __html: i18n.t("button.schedule")}} 
                            />
                        </div>

                        
                        <BlockCooperations
                            view = {"full"}
                        />
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(MyCooperations);

