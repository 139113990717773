import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import FormMeeting from '../forms/form-meeting';

const BlockMeeting = inject("stores") (
    observer (
        class BlockMeeting extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;
            }

            componentDidMount() {

            }

            componentDidUpdate() {

            }

            componentWillUnmount = () =>{
                const lng = 'nl';
                this.storeUi.setLocale(lng);
                i18n.changeLanguage(lng);
            }
      
            render() {
                return (
                    <div className="block--contact">
                        <span className="block--contact__subtitle">{i18n.t("subheaders.personal")}</span>
                        <h1 className="block--contact__title">{i18n.t("headers.meeting")}</h1>
                        <FormMeeting

                        />
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(BlockMeeting);
