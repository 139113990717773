import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { Helmet } from 'react-helmet';
import MetaTags from 'react-meta-tags';

import * as ui from '../utilities/ui';

import BlockQuote from '../components/blocks/block-quote';
import BlockBlogs from '../components/blocks/block-blogs';
import NavigationModules from '../components/grid/navigation-modules';
import BlockReviews from '../components/blocks/block-reviews';

const Start = inject("stores") (
    observer (
        class Start extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.env = process.env.REACT_APP_ENV;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;   
                this.storeContent = this.props.stores.storeContent;  

                this.state = {
                    slug: '',
                    pageblocks: []
                }  
                
                this.blockRef = React.createRef();
            }

            componentDidMount = async() => {
                const lng = 'nl';
                this.storeUi.setLocale(lng);
                i18n.changeLanguage(lng);
                let id;

                let pageblock;
                let pageblocks = [];

                const route = this.props.match.path;
                let slug = route.substring(1);

                if (this.env === 'DEV') {
                    const pages = await this.storeContent.getContentPages('pages', 100);
                    const idx = pages.findIndex(page => page.slug === 'start');
                    id = pages[idx].id;
                } else {
                    id = 2;
                }

                const content = await this.storeContent.getContentPage(id);
                if (this.env === 'DEV') {
                    console.log('SbC content api', content)
                }
                const pageQuote = content.BlockQuote;
                const bgPageQuote = content.BgBlockQuote;
                const seo = content.yoast_head_json;

                const featuredImage = content.better_featured_image.media_details.sizes.large.source_url;
                //const featuredImage = content.better_featured_image.source_url;

                Object.keys(content.acf).map(async (block) => {
                    slug = content.acf[block];

                    pageblock = this.storeContent.content_blocks.find(pageblock => pageblock.slug === slug);
                    if (pageblock !== undefined) {
                        pageblocks.push(pageblock);
                    }
                })

                this.setState({
                    content: content,
                    pageblocks: pageblocks,
                    pageQuote: pageQuote,
                    bgPageQuote: featuredImage,
                    seo: seo
                })
            }

            componentDidUpdate = async() => {
               
            }

            gotoBlock = () => {
                ui.smoothScroll(this.blockRef.offsetTop - 40);
            }

            render() {
                return (
                    <React.Fragment>
                        {/* <Helmet>
                            <title>Charles Ruiters Coaching, Mentoring & Supervisie</title>
                            <meta name="description" content="Coaching en meer" />
                        </Helmet> */}

                        <MetaTags>
                            <title>{this.state.seo && this.state.seo.og_title}</title>
                            <meta name="description" content={this.state.seo && this.state.seo.og_description} />
                            <meta property="og:title" content={this.state.seo && this.state.seo.og_title} />
                        </MetaTags>

                        <div className="wrapper--section wrapper--nav-modules">
                            <NavigationModules
                                pageblocks = {this.state.pageblocks}
                                content = {false}
                                active = {'coaches'}
                                lng = {this.storeUi.app_interface.lang}
                            />
                        </div>

                        <BlockQuote
                            pageQuote = {this.state.pageQuote}
                            bgPageQuote = {this.state.bgPageQuote}
                        />

                        <div className="form-row form-row--section form-row--buttons">
                            <button 
                                className={"btn btn--primary --inPhoto"} 
                                onClick={(e) => this.gotoBlock(e)}
                                dangerouslySetInnerHTML={{ __html: i18n.t("button.view_method")}} 
                            />
                        </div>

                        {this.state.pageblocks.map((pageblock, i) => (
                            (pageblock.slug !== 'particulieren' && pageblock.slug !== 'coaches') &&
                                <React.Fragment>
                                    {(pageblock.slug === 'methode-3' || pageblock.slug === 'methode-2') &&
                                        <div 
                                            className="wrapper-content--section"  
                                            key={i} 
                                            ref={ (ref) => this.blockRef=ref } 
                                        />
                                    }
                                    <div className="wrapper-content--section"  key={i}>                                     
                                        <div className="wrapper-content--text">
                                            <h2 className="page-content__title" dangerouslySetInnerHTML={{ __html: pageblock.title.rendered}} />
                                            <div className="page-content__text" dangerouslySetInnerHTML={{ __html: pageblock.content.rendered}} />

                                            <div className="form-row form-row--section form-row--buttons">
                                                <button 
                                                    className={"btn btn--primary btn--alternative " + this.state.btn_class} 
                                                    onClick={(e) => this.gotoPage(e)}
                                                    dangerouslySetInnerHTML={{ __html: i18n.t("button.schedule")}} 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                        ))}

                        <BlockReviews
                            section = {'all'}
                        />
                    
                        <BlockBlogs
                            view = {"summaries"}
                        />
                    </React.Fragment>
                );
            }
        }
    )
)

export default Start;
