import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import MetaTags from 'react-meta-tags';

import * as ui from '../utilities/ui';

import BlockBlogs from '../components/blocks/block-blogs';
import BlockHeaderImage from '../components/blocks/block-headerimage';

const Blog = inject("stores") (
    observer (
        class Blog extends React.Component {

            constructor(props) {
                super(props);
                this.state = {
                    slug: ''
                };

                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
            }

            componentDidMount = async () => {
                ui.handleScroll('top');

                if (this.storeContent.content_blogs.length === 0) {
                    const blogs = await this.storeContent.getContentPages('blogs', 100);
                    console.log('SbC get blogs', blogs)
                }

                const slug = this.props.match.params.title;
                //const featuredImage = await this.storeContent.getContentMedia(27675);
                const featuredImage = await this.storeContent.getContentMedia(this.storeContent.current_page.featured_media);

                // const route = this.props.match.path;
                // const slug = route.substring(1);
                // //SbC remove first / from route
                // //const id = this.props.match.params.id;

                // if (this.storeContent.content_pages.length === 0) {
                //     await this.storeContent.getContentPages();
                // }
                // const page =  this.storeContent.content_pages.find(page => page.slug === slug);
                // let content = await this.storeContent.getContentPage(page.id);

                // this.setState({
                //     content: content,
                //     slug: slug
                // })

                //let custom_fields = await this.storeContent.getCustomFields(id);
                this.setState({
                    featuredImage: featuredImage
                })
            }

            componentDidUpdate = async (prevProps) => {
                ui.handleScroll('top');

                if (prevProps.match.url !== this.props.match.url) {                   
                    const featuredImage = await this.storeContent.getContentMedia(this.storeContent.current_page.featured_media);

                    console.log('SbC featured media', featuredImage)

                    this.setState({
                        featuredImage: featuredImage
                    })
                }
            }

            render() {
                return (
                    <div className="page-content">
                        <BlockHeaderImage
                            section = {"blog"}
                            pageQuote = {this.storeContent.current_page.title.rendered}
                            bgPageQuote = {this.state.featuredImage}
                        />

                        <div className="page-content--primary page-content--blog">
                            <div className="wrapper-content--text">
                                {/* <span className="page-content__subtitle">blog</span>
                                {this.storeContent.current_page.title && 
                                    <h1 className="page-content__title" dangerouslySetInnerHTML={{ __html: this.storeContent.current_page.title.rendered}} />
                                } */}
                            
                                {this.storeContent.current_page.content && 
                                    <div className="page-content__text" dangerouslySetInnerHTML={{ __html: this.storeContent.current_page.content.rendered}} />
                                }
                            </div>
                        </div>

                        <BlockBlogs

                        />
                    </div>
                )
            }
        }
    )
)

export default withRouter(Blog);

