import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Switch, Route } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import GA4React from "ga-4-react";
import { Helmet } from 'react-helmet';

import Template from './templates/template';
import Start from './pages/start';
import ContentPage from './pages/content';
import MyBlogs from './pages/my-blogs';
import MyCooperations from './pages/my-cooperations';
import Blog from './pages/blog';
import Cooperation from './pages/cooperation';
import About from './pages/about';
import AboutEn from './pages/about-en';
import Videos from './pages/videos';

const ga4react = new GA4React("G-22M3DNVXMP");

const App = inject("stores") (
    observer (
        class App extends Component {
            constructor(props) {
                super(props);
                this.props = props;

                ga4react.initialize().then().catch()
            }
         
            render() {
                return (
                    <div className="app">
                        <Helmet>
                            <title>Charles Ruiters Coaching, Mentoring & Supervisie</title>
                            <meta name="description" content="Charles Ruiters Coaching, Mentoring & Supervisie" />
                        </Helmet>

                        <Template>
                            <Switch>
                                <Route 
                                    exact path = '/'
                                    component = {Start} 
                                />
                                <Route 
                                    path = {[
                                        '/about',
                                        '/about-charles-ruiters'
                                    ]} 
                                    component = {AboutEn} 
                                />
                                <Route 
                                    path = "/start" 
                                    component = {Start} 
                                />
                                <Route 
                                    path = "/mijn-blogs" 
                                    component = {MyBlogs} 
                                />
                                <Route 
                                    path = "/mijn-samenwerkingen" 
                                    component = {MyCooperations} 
                                />
                                <Route 
                                    path = "/blog/:title" 
                                    component = {Blog} 
                                />
                                <Route 
                                    path = "/samenwerking/:title" 
                                    component = {Cooperation} 
                                />
                                <Route 
                                    path = {[
                                        '/over-charles-ruiters'
                                    ]} 
                                    component = {About} 
                                />
                                <Route 
                                    path = {[
                                        '/contact',
                                        '/coaching',
                                        '/kennismakingsgesprek',
                                        '/meeting',
                                        '/personal-coaching',
                                        '/mentoring-voor-coaches'
                                    ]} 
                                    component = {ContentPage} 
                                />
                                <Route 
                                    path = "/videos" 
                                    component = {Videos} 
                                />
                            </Switch>
                        </Template>
                    </div>
                );
            }
        }
    )
)

export default App;
