import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import MetaTags from 'react-meta-tags';

import * as ui from '../utilities/ui';

import BlockCooperations from '../components/blocks/block-cooperations';
import BlockHeaderImage from '../components/blocks/block-headerimage';

const Cooperation = inject("stores") (
    observer (
        class Cooperation extends React.Component {

            constructor(props) {
                super(props);
                this.state = {
                    slug: ''
                };

                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
            }

            componentDidMount = async() => {
                ui.handleScroll('top');
    
                if (this.storeContent.content_cooperations.length === 0) {
                    const cooperations = await this.storeContent.getContentPages('cooperations', 100);
                    console.log('SbC get cooperations', cooperations)
                }

                // const route = this.props.match.path;
                // const slug = route.substring(1);
                const slug = this.props.match.params.title;
                console.log('SbC current coop...', this.storeContent.content_cooperations)

                const page =  this.storeContent.content_pages.find(page => page.slug === slug);
                //let content = await this.storeContent.getContentPage(page.id);

                console.log('SbC current coop 2', page)

                const featuredImage = await this.storeContent.getContentMedia(this.storeContent.current_page.featured_media);

                this.setState({
                    featuredImage: featuredImage
                })
                
                // //SbC remove first / from route
                // //const id = this.props.match.params.id;

                

                // this.setState({
                //     content: content,
                //     slug: slug
                // })

                //let custom_fields = await this.storeContent.getCustomFields(id);
            }

            componentDidUpdate = async(prevProps) => {
                ui.handleScroll('top');

                if (prevProps.match.url !== this.props.match.url) {                   
                    const featuredImage = await this.storeContent.getContentMedia(this.storeContent.current_page.featured_media);

                    this.setState({
                        featuredImage: featuredImage
                    })
                }
            }

            render() {
                return (
                    <div className="page-content">
                        <BlockHeaderImage
                            section = {"samenwerking"}
                            pageQuote = {this.storeContent.current_page.title.rendered}
                            bgPageQuote = {this.state.featuredImage}
                        />

                        <div className="page-content--primary">
                            <div className="wrapper-content--text">
                                {/* <span className="page-content__subtitle">samenwerking</span>
                                {this.storeContent.current_page.title && 
                                    <h1 className="page-content__title" dangerouslySetInnerHTML={{ __html: this.storeContent.current_page.title.rendered}} />
                                } */}
                                {this.storeContent.current_page.content && 
                                    <div className="page-content__text" dangerouslySetInnerHTML={{ __html: this.storeContent.current_page.content.rendered}} />
                                }
                            </div>
                        </div>

                        <BlockCooperations

                        />
                    </div>
                )
            }
        }
    )
)

export default withRouter(Cooperation);

