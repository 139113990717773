import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import MetaTags from 'react-meta-tags';

import * as ui from '../utilities/ui';

import BlockBlogs from '../components/blocks/block-blogs';
import BlockQuote from '../components/blocks/block-quote';
import BlockHeaderImage from '../components/blocks/block-headerimage';

const MyBlogs = inject("stores") (
    observer (
        class MyBlogs extends React.Component {

            constructor(props) {
                super(props);
                this.state = {
                    slug: '',
                    content: '',
                    title: ''
                };

                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
            }

            componentDidMount = async () => {
                ui.handleScroll('top');

                let featuredImage;
                let content = '';
                let title = '';

                if (this.storeContent.content_blogs.length === 0) {
                    const blogs = await this.storeContent.getContentPages('blogs', 100);

                    //featuredImage = await this.storeContent.getContentMedia(blogs[0].featured_media);
                    featuredImage = blogs[0].better_featured_image.media_details.sizes.thumbnail.source_url;

                    content = blogs[0].content.rendered;
                    title = blogs[0].title.rendered;
                } else {       
                    //featuredImage = await this.storeContent.getContentMedia(this.storeContent.content_blogs[0].featured_media);
                    featuredImage = this.storeContent.content_blogs[0].better_featured_image.media_details.sizes.large.source_url;
                    content = this.storeContent.content_blogs[0].content.rendered;
                    
                    title = this.storeContent.content_blogs[0].title.rendered;
                }

                let seo = content.yoast_head_json;
                const route = this.props.match.path;
                
                    const slug = route.substring(1);
                    //SbC remove first / from route
                    //const id = this.props.match.params.id;

                    // if (this.storeContent.content_pages.length === 0) {
                    //     await this.storeContent.getContentPages('pages');
                    // }

                    //if (route !== '/kennismakingsgesprek') {
                        //const page =  this.storeContent.content_pages.find(page => page.slug === slug);
                        //content = await this.storeContent.getContentPage(page.id);
                        //let acf = content.acf;

                        this.setState({
                            route: route,
                            content: content,
                            title: title,
                            slug: slug,
                            featuredImage: featuredImage,
                            seo: seo
                        })
                    // } else {
                    //     this.setState({
                    //         route: route
                    //     })
                    // }
            }

            componentDidUpdate = async (prevProps, prevState) => {
                ui.handleScroll('top');
                // let content;
                // let pageblock;
                // let pageblocks = [];

                // const route = this.props.match.path;
                // let slug = route.substring(1);

                // if (prevState.route !== route) {
                //     let page = this.storeContent.content_pages.find(page => page.slug === slug);
                //     //content = await this.storeContent.getContentPage(page.id);
                //     const pageQuote = content.BlockQuote;
                //     const bgPageQuote = content.BgBlockQuote;

                //     console.log('SbC acf::', content.acf)

                //     Object.keys(content.acf).map(async (block) => {
                //         slug = content.acf[block];

                //         pageblock = this.storeContent.content_blocks.find(pageblock => pageblock.slug === slug);
                //         if (pageblock !== undefined) {
                //             pageblocks.push(pageblock);
                //         }
                //     })

                //     this.setState({
                //         route: route,
                //         //content: content,
                //         slug: slug,
                //         pageblocks: pageblocks,
                //         pageQuote: pageQuote,
                //         bgPageQuote: bgPageQuote
                //     })
                // }
            }

            render() {
                return (
                    <React.Fragment>
                        <MetaTags>
                            <title>{this.state.seo && this.state.seo.og_title}</title>
                            <meta name="description" content={this.state.seo && this.state.seo.og_description} />
                            <meta property="og:title" content={this.state.seo && this.state.seo.og_title} />
                        </MetaTags>
                    
                        <div className="page-content">
                            <BlockHeaderImage
                                section = {"laatste blog"}
                                pageQuote = {this.state.title}
                                bgPageQuote = {this.state.featuredImage}
                            />

                            <div className="page-content--primary page-content--blog">
                                <div className="wrapper-content--text">     
                                    <div className="page-content__text" dangerouslySetInnerHTML={{ __html: this.state.content}} />
                                </div>
                            </div>

                            <BlockBlogs
                                view = {"full"}
                            />
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(MyBlogs);

