import React, { Component } from 'react';
import PropTypes from "prop-types";

class Bars extends Component {

    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        return (
            <svg
                width={this.props.width}
                height={this.props.height}
                viewBox="0 0 135 140"
                xmlns="http://www.w3.org/2000/svg"
                aria-label={this.props.label}
            >
                <style>
                    { `.class1 { fill:${this.props.colors && this.props.colors[0]} }` }
                    { `.class2 { fill:${this.props.colors && this.props.colors[1]} }` }
                    { `.class3 { fill:${this.props.colors && this.props.colors[2]} }` }
                </style>
                <rect y="10" width="15" height="40" rx="6" className="class1">
                <animate
                    attributeName="height"
                    begin="0.5s"
                    dur="1s"
                    values="120;110;100;90;80;70;60;50;40;140;120"
                    calcMode="linear"
                    repeatCount="indefinite"
                />
                <animate
                    attributeName="y"
                    begin="0.5s"
                    dur="1s"
                    values="10;15;20;25;30;35;40;45;50;0;10"
                    calcMode="linear"
                    repeatCount="indefinite"
                />
                </rect>
                <rect x="30" y="10" width="15" height="120" rx="6" className="class1">
                <animate
                    attributeName="height"
                    begin="0.25s"
                    dur="1s"
                    values="120;110;100;90;80;70;60;50;40;140;120"
                    calcMode="linear"
                    repeatCount="indefinite"
                />
                <animate
                    attributeName="y"
                    begin="0.25s"
                    dur="1s"
                    values="10;15;20;25;30;35;40;45;50;0;10"
                    calcMode="linear"
                    repeatCount="indefinite"
                />
                </rect>
                <rect x="60" width="15" height="140" rx="6" className="class2">
                <animate
                    attributeName="height"
                    begin="0s"
                    dur="1s"
                    values="120;110;100;90;80;70;60;50;40;140;120"
                    calcMode="linear"
                    repeatCount="indefinite"
                />
                <animate
                    attributeName="y"
                    begin="0s"
                    dur="1s"
                    values="10;15;20;25;30;35;40;45;50;0;10"
                    calcMode="linear"
                    repeatCount="indefinite"
                />
                </rect>
                <rect x="90" y="10" width="15" height="120" rx="6" className="class3">
                <animate
                    attributeName="height"
                    begin="0.25s"
                    dur="1s"
                    values="120;110;100;90;80;70;60;50;40;140;120"
                    calcMode="linear"
                    repeatCount="indefinite"
                />
                <animate
                    attributeName="y"
                    begin="0.25s"
                    dur="1s"
                    values="10;15;20;25;30;35;40;45;50;0;10"
                    calcMode="linear"
                    repeatCount="indefinite"
                />
                </rect>
                <rect x="120" y="10" width="15" height="120" rx="6" className="class3">
                <animate
                    attributeName="height"
                    begin="0.5s"
                    dur="1s"
                    values="120;110;100;90;80;70;60;50;40;140;120"
                    calcMode="linear"
                    repeatCount="indefinite"
                />
                <animate
                    attributeName="y"
                    begin="0.5s"
                    dur="1s"
                    values="10;15;20;25;30;35;40;45;50;0;10"
                    calcMode="linear"
                    repeatCount="indefinite"
                />
                </rect>
            </svg>
        )
    }
}

export default Bars;

Bars.defaultProps = {
    height: 80,
    width: 80,
    color: "green",
    label: "loading"
};

