import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import i18n from "i18next";

const BlockBlog = inject("stores") (
    observer (
        class BlockBlogs extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeContent = this.props.stores.storeContent; 

                this.state = {
                   
                };
            }

            componentDidMount = async() => {
                //const featuredImage = await this.storeContent.getContentMedia(this.props.blog.featured_media);
                const featuredImage = this.props.blog.better_featured_image.media_details.sizes.thumbnail.source_url;

                this.setState({
                    featuredImage: featuredImage
                })
            }

            componentDidUpdate() {

            }

            gotoBlog = (blog, slug) => {
                this.storeContent.setCurrentPage(blog);

                let url = "/blog/" + slug;
                this.props.history.push(url);
            }

            gotoPage = () => {
                let url = '/mijn-blogs';
                this.props.history.push(url);
            }
      
            render() {
                return (
                    <li 
                        className="block--blogs__listitem --link-clean"
                        onClick = {(e) => this.gotoBlog(this.props.blog, this.props.blog.slug)}
                    >
                        <div className="list--blogs__container-image">
                            <div className="list--blogs__image">
                                <img src={this.state.featuredImage} />
                            </div>
                        </div>
                        <div className="list--blogs__container-content">
                            <span 
                                className="list--blogs__title"
                                dangerouslySetInnerHTML={{ __html: this.props.blog.title.rendered}} 
                            />
                            <span 
                                className="list--blogs__content"
                                dangerouslySetInnerHTML={{ __html: this.props.blog.excerpt.rendered}} 
                            />
                            <span 
                                className = "link--read-more --link"
                                onClick = {(e) => this.gotoBlog(this.props.blog, this.props.blog.slug)}
                            >
                                Lees verder
                                <div className="wrapper-icon">    
                                    <div className="wrapper-icon__icon"></div>
                                </div>
                            </span>
                        </div>
                    </li>
                )
            }
        }
    )
)

export default withRouter(BlockBlog);
