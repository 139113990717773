import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";

import BlockVideo from './block-video';

const BlockReviews = inject("stores") (
    observer (
        class BlockReviews extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi; 
                this.storeContent = this.props.stores.storeContent; 

                this.state = {
                    reviews: []
                };
            }

            componentDidMount = async() => {
                const reviews = await this.storeContent.getContentPages('reviews', 100);
                const reviewsAscending = [...reviews].sort((a, b) => a.ReviewOrder - b.ReviewOrder);

                this.setState({
                    reviews: reviewsAscending
                })
            }

            componentDidUpdate() {

            }

            onSlideChange = () => {
                this.setState({
                    video_playing: false
                })
            }

            handleVideoPlaying = () => {
                this.setState({
                    video_playing: true
                })
            }

            render() {
                return (
                    <div className="block--reviews">
                        <span className="block--reviews__subtitle">Reviews</span>
                        <h1 className="block--reviews__title">Ervaringen van deelnemers</h1>

                        <div className="carousel carousel--reviews">
                            <Swiper 
                                centeredSlides = {true}
                                slidesPerView={1}
                                navigation = {true}
                                modules={[Navigation]}
                                className="contentSwiper"
                                onSlideChange={this.onSlideChange}
                            >
                                {this.state.reviews && this.state.reviews.map((review, i) => (
                                    (review.CoachSection == this.props.section || this.props.section === 'all') &&
                                        <SwiperSlide key={i}>
                                            <div className="carousel--banner__slide">
                                                <div className="carousel--banner__slide-content">
                                                    <div className="list--reviews__container-content">
                                                        <span 
                                                            className="list--reviews__title"
                                                            dangerouslySetInnerHTML={{ __html: review.title.rendered}} 
                                                        />
                                                        {review.ReviewType === 'video' &&
                                                            <BlockVideo
                                                                video = {"https://charlesruiters.com/api/public/videos/" + review.VideoFilename}
                                                                isDialogVideoOpen = {this.storeUi.isDialogVideoOpen}
                                                                //active_video_file = {"https://charlesruiters.com/api/public/videos/" + review.VideoFilename}
                                                                orientation = {review.VideoOrientation}
                                                                active_video = {this.storeUi.active_video}
                                                                idx = {i}
                                                                video_playing = {this.state.video_playing}
                                                                handleVideoPlaying = {this.handleVideoPlaying}
                                                            />
                                                        }
                                                        {review.ReviewType !== 'video' &&
                                                            <span 
                                                                className="list--reviews__content"
                                                                dangerouslySetInnerHTML={{ __html: review.content.rendered}} 
                                                            />
                                                        }
                                                        <span 
                                                            className="list--reviews__content--reviewer"
                                                        >
                                                            {review.Reviewer}
                                                        </span> 
                                                        <span 
                                                            className="list--reviews__content--function"
                                                        >
                                                            {review.ReviewerFunction}
                                                        </span> 
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                ))}
                                
                            </Swiper>
                            {/* <div className="swiper-button" ref={nextRef}>
                            next
                            </div> */}
                        </div>
                        
                        {/* <ul className="block--reviews__list block--reviews__list --collapsed">
                        {this.state.reviews && this.state.reviews.map((review, i) => (
                            (review.CoachSection == this.props.section || this.props.section === 'all') &&
                                <li className="block--reviews__listitem" key={i}>
                                    
                                </li>
                        ))}
                        </ul> */}

                    </div>
                )
            }
        }
    )
)

export default BlockReviews;
