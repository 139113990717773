import React, { Component } from 'react';

import Bars from './loader-bars';

class Loader extends Component {

    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {     
        return (
            <div className="loader-wrapper">
                <div className="loader-wrapper__loader">
                    <Bars
                        colors = {this.props.colors} 
                    />
                </div>
            </div>
        )
    }
}

export default Loader;
