import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import NavigationMain from './navigation-main';

const Header = inject("stores") (
    observer (
        class Header extends Component {

            constructor(props) {
                super(props);
                this.props = props;
            }

            componentDidMount() {

            }

            componentDidUpdate() {

            }

            handleHome = () => {
                const url = '/';
                this.props.history.push(url);
            }
      
            render() {
                return (
                    <div className="header">
                        <div 
                            className="header__branding --link" 
                            onClick={() => this.handleHome()}
                        >
                            <img src="https://www.charlesruiters.com/api/public/images/branding/logo-web.png" />
                        </div>

                        <div className="header__navigation">
                            <NavigationMain

                            />
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withRouter(Header);
