export async function kkFetch(endPoint, method, data) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndPoint = urlAPI + endPoint;
    let params;
 
    if (method == 'GET') {
        params = {
            method: method,
            headers: new Headers({
         
            })
        }
    } 

    if (method == 'POST') {
        const postData = data;
        params = {
            method: method,
            body: JSON.stringify(postData),
            headers: new Headers({
           
            })
        }
    } 

    try {
        let response = await fetch(urlEndPoint, params);
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;
            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error: ', urlEndPoint, error.message);
    }
}