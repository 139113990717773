import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";

const NavigationMain = inject("stores") (
    observer (
        class NavigationMain extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.env = process.env.REACT_APP_ENV;
                this.storeContent = this.props.stores.storeContent; 

                this.state = {
                    menu: []
                };
            }

            componentDidMount = async() => {
                let idx;
                if (this.env === 'DEV') {
                    //idx = 2;
                    idx = 4;
                } else {
                    idx = 4;
                }

                const menu = await this.storeContent.getMenu(idx);
                this.setState({
                    menu: menu
                })
            }

            componentDidUpdate = async() => {

            }

            handleClickNav = (slug) => {
                const url = '/' + slug;
                this.props.history.push(url);
            }

            changeLang = () => {
                const url = '/about';
                this.props.history.push(url);
            }
      
            render() {
                return (
                    <div className="wrapper--navigation-main">
                        <ul>
                            {this.state.menu && this.state.menu.map((item, i) => (
                                <li 
                                    className="--link" 
                                    onClick={() => this.handleClickNav(item.object_slug)} 
                                    key={i}
                                >
                                    <Link to={"/" + item.object_slug}>{item.title}</Link>
                                </li>
                            ))}
                            <li
                                className="--link" 
                                onClick={() => this.changeLang()} 
                            >
                                EN
                            </li>
                        </ul>
                    </div>
                )
            }
        }
    )
)

export default withRouter(NavigationMain);
