import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

const BlockAddress = inject("stores") (
    observer (
        class BlockAddress extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi; 
            }

            componentDidMount = async() => {

            }
      
            render() {
                return (
                    <div className="footer__address"> 
                        <ul className="address__list"> 
                            <li className="address__list address__listitem">{i18n.t("content.business_address")}:</li> 
                            <li className="address__list address__listitem">Titanenhof 15</li> 
                            <li className="address__list address__listitem">6215 EA Maastricht</li> 
                            <li>KvK 76310469</li>
                            <li>{i18n.t("content.vat")} NL003071114b03</li>
                        </ul> 
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(BlockAddress);
