import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import FormContact from '../forms/form-contact';

const BlockContact = inject("stores") (
    observer (
        class BlockContact extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi; 
            }

            componentDidMount = () => {
                if (this.props.lng === 'en' || this.storeUi.app_interface.lang === 'en') {
                    const lng = 'en';
                    this.storeUi.setLocale(lng);
                    i18n.changeLanguage(lng);
                }
            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.lng !== this.props.lng) {

                }
            }
      
            render() {
                return (
                    <div className="block--contact">
                        <span className="block--contact__subtitle">{i18n.t("subheaders.contact")}</span>
                        <h1 className="block--contact__title">{i18n.t("headers.contact")}</h1>
                        <FormContact
                            lng = {this.storeUi.app_interface.lang} 
                        />
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(BlockContact);
