import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import BlockVideo from '../components/blocks/block-video';

const Videos = inject("stores") (
    observer (
        class Videos extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeUi = this.props.stores.storeUi;

                this.state = {
                }
            }

            componentDidMount() {
                window.scrollTo(0, 0);
                //this.storeUi.getVideos();
                //this.storeUi.setActiveVideo(-1);
            }

            render() {
                return (
                    <div>
                         <div className="content-segment">
                            <div className="blocks--video">
                                {/* {this.storeUi.videos && this.storeUi.videos.map((video, idx) => (                               */}
                                    <BlockVideo
                                        video = {this.storeUi.active_video}
                                        isDialogVideoOpen = {this.storeUi.isDialogVideoOpen}
                                        //idx = {idx}
                                        active_video = {this.storeUi.active_video}
                                    />
                                {/* ))} */}
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(Videos);
