import i18n from "i18next";
import XHR from 'i18next-xhr-backend';
//import LanguageDetector from "i18next-browser-languagedetector";

const urlAPI = process.env.REACT_APP_URL_API;

const backendOpts = {
    loadPath: urlAPI + `/translation/translation.php?lang={{lng}}`,
    crossDomain: true,
    allowMultiLoading: true
}

i18n
    //.use(LanguageDetector)
    .use(XHR)
    .init({
        lng: "nl",
        preload: ['en', 'nl'],
        load: 'languageOnly',
        backend: backendOpts,

        fallbackLng: "nl",
        //debug: process.env.NODE_ENV === "development",
        defaultNS: "translation",
       
        order: ['localStorage', 'querystring', 'cookie', 'navigator', 'htmlTag', 'path', 'subdomain'],
        lookupQuerystring: 'lng',
        lookupCookie: 'i18next',
        lookupLocalStorage: 'i18nextLng',
        caches: ['localStorage', 'cookie'],

        interpolation: {
            escapeValue: false,
            formatSeparator: ","
        },

        react: {
            wait: true
        }
    });

export default i18n;