import { makeObservable, observable, computed, action } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";
import { ConsoleView } from "react-device-detect";

class StoreGeneral {

    settings = {
        email_contact: 'contact@feelgoodvuurwerk.nl',
        url_fb: '',
        url_insta: '',
        url_twitter: ''
    }

    socials = [{
            name: 'facebook',
            url: 'https://www.facebook.com/feelgoodvuurwerk',
            icon: 'ic-social-facebook.png'
            
        },{
            name : 'instagram',
            url: 'https://www.instagram.com/feelgoodvuurwerk/',
            icon: 'ic-social-instagram.png'
            
        },{
            name : 'twitter',
            url: 'https://twitter.com/FeelgoodVuurwrk',
            icon: 'ic-social-twitter.png'
        },{
            name : 'linkedin',
            url: 'https://www.linkedin.com/company/feelgood-vuurwerk/',
            icon: 'ic-social-linkedin.png'
        }
    ]

    constructor() {
        makeObservable(this, {
            settings: observable
        })
    }
}

export default StoreGeneral;