import React from 'react';

const TextArea = props => {

    let formClass = "form-field--textarea";
    
    if (props.className && props.className !== '') {
        formClass = formClass + ' ' + props.className;
    }

    //if (props.touched && !props.valid) {
    if (!props.valid && props.value !== '') {
        formClass = formClass + ' --error';
    }

    return (
        <div className="form-group">
            <textarea 
                {...props} 
                className = {formClass} 
                maxLength = {props.maxLength}
            />
            {!props.valid && props.value !== '' && 
                <span className="form-field--error">Deze invoer is niet correct.</span>
            }
        </div>
    );
}

export default TextArea;