import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import i18n from "i18next";

import BlockBlog from './block-blog';

const BlockBlogs = inject("stores") (
    observer (
        class BlockBlogs extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeContent = this.props.stores.storeContent; 

                this.state = {
                    blogs: []
                };
            }

            componentDidMount = async() => {
                let amount = 100;
                const route = this.props.match.path;

                if (route !== '/mijn-blogs') {
                    amount = 10;
                }

                const blogs = await this.storeContent.getContentPages('blogs', amount);

                this.setState({
                    blogs: blogs
                })
            }

            componentDidUpdate() {

            }

            gotoBlog = (blog, slug) => {
                this.storeContent.setCurrentPage(blog);

                let url = "/blog/" + slug;
                this.props.history.push(url);
            }

            gotoPage = () => {
                let url = '/mijn-blogs';
                this.props.history.push(url);
            }
      
            render() {
                return (
                    <div className="block--blogs">
                        <span className="block--blogs__subtitle">Mijn blogs</span>
                        <h1 className="block--blogs__title">Wat ik jou wil meegeven</h1>
                        
                        <ul className={"block--blogs__list block--blogs__list" + (this.props.view === "summaries" ? " --collapsed" : "")}>
                        {this.state.blogs && this.state.blogs.map((blog, i) => (
                            <BlockBlog
                                key = {i}
                                blog = {blog}
                            />
                        ))}
                        </ul>
                        
                        {this.props.view === "summaries" &&
                            <div className="form-row form-row--buttons">
                                <button 
                                    className={"btn btn--primary btn--alternative " + this.state.btn_class} onClick={(e) => this.gotoPage(e)}
                                    dangerouslySetInnerHTML={{ __html: i18n.t("button.blogs")}} 
                                />
                            </div>
                        }
                    </div>
                )
            }
        }
    )
)

export default withRouter(BlockBlogs);
