import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import MetaTags from 'react-meta-tags';

import * as ui from '../utilities/ui';

import BlockCooperations from '../components/blocks/block-cooperations';
import BlockReviews from '../components/blocks/block-reviews';
import BlockQuote from '../components/blocks/block-quote';
import BlockMeeting from '../components/blocks/block-meeting';
import NavigationModules from '../components/grid/navigation-modules';

const AboutEn = inject("stores") (
    observer (
        class AboutEn extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;   
                this.storeContent = this.props.stores.storeContent;  

                this.state = {
                    pageQuote: 'x',
                    pageblocks: [],
                    content: ''
                } 
                
                this.slug1 = 'about';
                this.slug2 = 'about-charles-ruiters';
            }

            componentDidMount = async() => {
                ui.handleScroll('top');

                const lng = 'en';
                this.storeUi.setLocale(lng);
                i18n.changeLanguage(lng);

                const route = this.props.match.path;

                if (route !== '/kennismakingsgesprek') {
                    let slug = route.substring(1);
                    if (slug === this.slug1 || slug === this.slug2) {
                        slug = this.slug2;
                    }
                    
                    if (this.storeContent.content_pages.length === 0) {
                        await this.storeContent.getContentPages('pages', 100);
                    }

                    const page =  this.storeContent.content_pages.find(page => page.slug === slug);
                    let content = await this.storeContent.getContentPage(page.id);
                    let seo = content.yoast_head_json;
                    let acf = content.acf;

                    this.setState({
                        route: route,
                        content: content,
                        slug: slug,
                        acf: acf,
                        seo: seo
                    })
                } else {
                    this.setState({
                        route: route
                    })
                }
            }

            componentDidUpdate = async (revProps, prevState) => {
                ui.handleScroll('top');
                let content;
                let pageblock;
                let pageblocks = [];

                const route = this.props.match.path;
                let slug = route.substring(1);
                if (slug === this.slug1 || slug === this.slug2) {
                    slug = this.slug2;
                }

                if (prevState.route !== route) {
                    let page = this.storeContent.content_pages.find(page => page.slug === slug);
                    content = await this.storeContent.getContentPage(page.id);
                    const pageQuote = content.BlockQuote;
                    const bgPageQuote = content.BgBlockQuote;

                    Object.keys(content.acf).map(async (block) => {
                        slug = content.acf[block];

                        pageblock = this.storeContent.content_blocks.find(pageblock => pageblock.slug === slug);
                        if (pageblock !== undefined) {
                            pageblocks.push(pageblock);
                        }
                    })

                    this.setState({
                        route: route,
                        content: content,
                        slug: slug,
                        pageblocks: pageblocks,
                        pageQuote: pageQuote,
                        bgPageQuote: bgPageQuote
                    })
                }
            }

            componentWillUnmount = () => {
                this.storeUi.setLocale('nl');
                i18n.changeLanguage('nl');
            }

            gotoPage = () => {
                let url = '/meeting';
                this.props.history.push(url);
            }

            render() {
                

                return (
                    <React.Fragment>
                        <MetaTags>
                            <title>{this.state.seo && this.state.seo.og_title}</title>
                            <meta name="description" content={this.state.seo && this.state.seo.og_description} />
                            <meta property="og:title" content={this.state.seo && this.state.seo.og_title} />
                        </MetaTags>
                        
                        <BlockQuote
                            pageQuote = {this.state.pageQuote}
                            bgPageQuote = {this.state.bgPageQuote}
                        />

                        {this.state.content && this.state.content.content.rendered !== '' &&
                        <div className="wrapper--section">
                            <div className="page--content">
                                <div className="wrapper-content--text">
                                    {this.state.content &&
                                        <div className="wp-page-content wp-page-content--start">
                                            <h1>{this.state.content && this.state.content.title.rendered}</h1>
                                            <p dangerouslySetInnerHTML={{ __html: this.state.content.content.rendered}} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        }

                        {this.state.pageblocks.map((pageblock) => (
                            (pageblock.slug !== 'particulieren' && pageblock.slug !== 'coaches') &&
                                <div className="wrapper-content--section">
                                    <div className="wrapper-content--text">
                                        <h2 className="page-content__title" dangerouslySetInnerHTML={{ __html: pageblock.title.rendered}} />
                                        <div className="page-content__text" dangerouslySetInnerHTML={{ __html: pageblock.content.rendered}} />

                                        <div className="form-row form-row--section form-row--buttons">
                                            <button 
                                                className={"btn btn--primary btn--alternative " + this.state.btn_class} 
                                                onClick={(e) => this.gotoPage(e)}
                                                dangerouslySetInnerHTML={{ __html: i18n.t("button.schedule")}} 
                                            />
                                        </div>
                                    </div>
                                </div>
                        ))}
                    
                        <div className="wrapper--section wrapper--nav-modules">
                            <NavigationModules
                                pageblocks = {this.state.pageblocks}
                                content = {true}
                            />
                        </div>

                        {/* <BlockReviews
                            section = {'all'}
                        /> */}

                        {/* <BlockCooperations
                            view = {"summaries"}
                        /> */}
                    </React.Fragment>
                );
            }
        }
    )
)

export default AboutEn;
