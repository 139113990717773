import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { toJS } from 'mobx';

const BlockSocial = inject("stores") (
    observer (
        class BlockSocial extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                // this.linksSocial = [
                //     {
                //         name: 'facebook',
                //         displayValue: 'Facebook',
                //         icon: 'ic-facebook-zw-trans.png',
                //         link: 'https://www.facebook.com/charlesruiters'
                //     },{
                //         name: 'twitter',
                //         displayValue: 'Twitter',
                //         icon: 'ic-twitter-zw-trans.png',
                //         link: 'https://www.twitter.com'
                //     },{
                //         name: 'linkedin',
                //         displayValue: 'Linkedin',
                //         icon: 'ic-linkedin-zw-trans.png',
                //         link: 'https://www.linkedin.com/in/charlesruiters/'
                //     },{
                //         name: 'youtube',
                //         displayValue: 'Youtube',
                //         icon: 'ic-youtube-zw-trans.png',
                //         link: 'https://www.youtube.com/channel/UC4y8JDhgFyfLxaP3pgGykHQ?view_as=subscriber'
                //     }
                // ]
                this.linksSocial = [
                    {
                        name: 'linkedin',
                        displayValue: 'Linkedin',
                        icon: 'ic-linkedin-zw-trans.png',
                        link: 'https://www.linkedin.com/in/charlesruiters/'
                    },{
                        name: 'youtube',
                        displayValue: 'Youtube',
                        icon: 'ic-youtube-zw-trans.png',
                        link: 'https://www.youtube.com/channel/UC4y8JDhgFyfLxaP3pgGykHQ?view_as=subscriber'
                    }
                ]
            }

            componentDidMount() {

            }

            componentDidUpdate() {

            }

            handleLinkSocial = (url) => {
                window.open(url);
            }
      
            render() {
                return (
                    <div className="block--social">
                        <ul className="block--social__list">
                            {this.linksSocial.map((social, i) => (
                                <li className="block--social__listitem --link" key={i}>
                                    <img 
                                        src={this.urlAPI + "public/images/icons/" + social.icon}
                                        onClick = {() => this.handleLinkSocial(social.link)}
                                        alt=""
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                )
            }
        }
    )
)

export default BlockSocial;
