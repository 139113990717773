import { makeAutoObservable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';

class StoreUi {
    app_interface = { 
        lang: 'nl',
        lang_id: 0,
        height_footer: 0,
        isDialogContentOpen: false
    }  
    menu_mobile = false
    content_component = ''

    settings_list = {
        maxLengthProfile: 500
    }

    isDialogVideoOpen = false;
    active_video = 0;

    clearFormFeedback = () => {

    }
    
    closeDialogContent = () => {
        this.app_interface.isDialogContentOpen = false;
    }

    openDialogContent = () => {
        this.app_interface.isDialogContentOpen = true;
    }

    setContentComponent(comp) {
        this.content_component = comp;
    }
    
    setHeightFooter(num) {
        this.app_interface.height_footer = num;
    }

    setMobileMenu() {
        this.menu_mobile = !this.menu_mobile;
    }

    setActiveVideo(idx) {
        this.active_video = idx;
    }

    setLocale(lang) {
        this.app_interface.lang = lang;
        switch(lang) {
            case 'nl':
                this.app_interface.lang_id = 1;
                break;
            case 'en':
                this.app_interface.lang_id = 0;
                break;
            default:
               this.app_interface.lang_id = 1;
        } 
    }
}

// decorate(StoreUi, {
//     app_interface: observable,
//     content_component: observable,
//     menu_mobile: observable,
//     closeDialogContent: action,
//     openDialogContent: action,
//     setContentComponent: action,
//     setHeightFooter: action,
//     setMobileMenu: action
// })

export default StoreUi;