import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

const BlockHeaderImage = inject("stores") (
    observer (
        class BlockHeaderImage extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.env = process.env.REACT_APP_ENV;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeContent = this.props.stores.storeContent; 

                this.state = {
                    bgImg: ""
                } 
            }

            componentDidMount = async() => {
                //let idx;
                // const pages = await this.storeContent.getContentSingle('quotes', 45);
                // const idx = pages.findIndex(page => page.slug === 'start');
                //const content = await this.storeContent.getContentPage(pages[idx].id);
                // if (this.env === 'DEV') {
                //     idx = 22;
                // } else {
                //     idx = 11;
                // }
                const idx = this.props.pageQuote;

                // const content = await this.storeContent.getContentSingle('blockquotes', idx);

                // this.setState({
                //     content: content
                // })
                const bgImg = this.props.bgPageQuote;
                const content = this.props.pageQuote;

                this.setState({
                    content: content,
                    bgImg: bgImg
                })
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.pageQuote !== this.props.pageQuote || prevProps.bgPageQuote !== this.props.bgPageQuote) {
                    const idx = this.props.pageQuote;
                    const bgImg = this.props.bgPageQuote;
                    //const content = await this.storeContent.getContentSingle('blockquotes', idx);
                    const content = this.props.pageQuote;

                    this.setState({
                        content: content,
                        bgImg: bgImg
                    })
                }
            }
      
            render() {
                return (
                    <div className="block--quote">
                        {this.state.bgImg !== '' &&
                            <div 
                                className="block--quote__background"
                                style= {{backgroundImage:`url(${this.state.bgImg})` }}
                            />
                        }
                        <div className={"block--section__fade bg-gradient-header"} />
                        <div className="block--quote__header">
                            <span className="block--headerimg__subheader">{this.props.section}</span>
                            <span 
                                className="block--headerimg__header"
                                dangerouslySetInnerHTML={{ __html: this.state.content && this.state.content}} 
                            />
                        </div>
                    </div>
                )
            }
        }
    )
)

export default BlockHeaderImage;
