import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import MetaTags from 'react-meta-tags';

import * as ui from '../utilities/ui';

import BlockQuote from '../components/blocks/block-quote';
import BlockMeeting from '../components/blocks/block-meeting';
import BlockReviews from '../components/blocks/block-reviews';
import NavigationModules from '../components/grid/navigation-modules';

const ContentPage = inject("stores") (
    observer (
        class ContentPage extends React.Component {

            constructor(props) {
                super(props);
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;

                this.state = {
                    slug: '',
                    pageblocks: []
                };

                this.blockRef = React.createRef();
            }

            componentDidMount = async () => {
                const lng = 'nl';
                this.storeUi.setLocale(lng);

                ui.handleScroll('top');

                const route = this.props.match.path;

                //if (route !== '/kennismakingsgesprek') {
                    let slug = route.substring(1);
                    if (slug === 'meeting') {
                        const lng = 'en';
                        this.storeUi.setLocale(lng);
                        i18n.changeLanguage(lng);
                        slug = 'kennismakingsgesprek';
                    }
                    //SbC remove first / from route
                    //const id = this.props.match.params.id;

                    if (this.storeContent.content_pages.length === 0) {
                        await this.storeContent.getContentPages('pages', 100);
                    }

                    //if (route !== '/kennismakingsgesprek') {
                        const page =  this.storeContent.content_pages.find(page => page.slug === slug);
                        let content = await this.storeContent.getContentPage(page.id);
                        let seo = content.yoast_head_json;
                        let acf = content.acf;

                        this.setState({
                            route: route,
                            content: content,
                            slug: slug,
                            acf: acf,
                            seo: seo
                        })
                    // } else {
                    //     this.setState({
                    //         route: route
                    //     })
                    // }
            }

            componentDidUpdate = async (prevProps, prevState) => {
                ui.handleScroll('top');
                let content;
                let pageblock;
                let pageblocks = [];

                const route = this.props.match.path;
                let slug = route.substring(1);

                if (slug === 'meeting') {
                    const lng = 'en';
                    this.storeUi.setLocale(lng);
                    i18n.changeLanguage(lng);
                    slug = 'kennismakingsgesprek';
                }

                if (prevState.route !== route) {
                    let page = this.storeContent.content_pages.find(page => page.slug === slug);
                    content = await this.storeContent.getContentPage(page.id);
                    console.log('SbC set quote')
                    const pageQuote = content.BlockQuote;
                    const bgPageQuote = content.BgBlockQuote;

                    Object.keys(content.acf).map(async (block) => {
                        slug = content.acf[block];

                        pageblock = this.storeContent.content_blocks.find(pageblock => pageblock.slug === slug);
                        if (pageblock !== undefined) {
                            pageblocks.push(pageblock);
                        }
                    })

                    this.setState({
                        route: route,
                        content: content,
                        slug: slug,
                        pageblocks: pageblocks,
                        pageQuote: pageQuote,
                        bgPageQuote: bgPageQuote
                    })
                }
            }

            gotoBlock = () => {
                ui.smoothScroll(this.blockRef.offsetTop - 40);
            }

            gotoPage = (e, page) => {
                let url = '';

                if (page === 'meeting') {
                    url = '/kennismakingsgesprek';
                }

                if (page === 'about') {
                    url = '/over-charles-ruiters';
                }

                this.props.history.push(url);
            }

            render() {
                return (
                    <React.Fragment>
                        <MetaTags>
                            <title>{this.state.seo && this.state.seo.og_title}</title>
                            <meta name="description" content={this.state.seo && this.state.seo.og_description} />
                            <meta property="og:title" content={this.state.seo && this.state.seo.og_title} />
                        </MetaTags>
                    
                        <div className="page-content">
                            {(this.state.route === '/personal-coaching' || this.state.route === '/mentoring-voor-coaches') && 
                                <div className="wrapper--section wrapper--nav-modules">
                                    <NavigationModules
                                        pageblocks = {this.state.pageblocks}
                                        content = {false}
                                        active = {this.state.route === '/mentoring-voor-coaches' ? 'coaches' : 'individuals'}
                                    />
                                </div>
                            }

                            <BlockQuote
                                pageQuote = {this.state.pageQuote}
                                bgPageQuote = {this.state.bgPageQuote}
                            />

                            {(this.state.route === '/mentoring-voor-coaches' || this.state.route === '/personal-coaching') && 
                                <div className="form-row form-row--section form-row--buttons">
                                    <button 
                                        className={"btn btn--primary --inPhoto"} 
                                        onClick={(e) => this.gotoBlock(e)}
                                        dangerouslySetInnerHTML={{ __html: i18n.t("button.view_method")}} 
                                    />
                                </div>
                            }

                            {this.state.route === '/coaching' && 
                                <div className="wrapper--section wrapper--nav-modules">
                                    <NavigationModules
                                        pageblocks = {this.state.pageblocks}
                                        content = {true}
                                    />
                                </div>
                            }
                            
                            {this.state.route !== '/contact' && this.state.route !== '/kennismakingsgesprek' && this.state.route !== '/meeting' && this.state.route !== '/coaching' &&
                                <React.Fragment>
                                <div className="page-content--primary">
                                    <div className="wrapper-content--text">
                                        {this.storeContent.current_page.title && 
                                            <h1 className="page-content__title" dangerouslySetInnerHTML={{ __html: this.storeContent.current_page.title.rendered}} />
                                        }
                                        {this.storeContent.current_page.content && 
                                            <div 
                                                className="page-content__text" 
                                                dangerouslySetInnerHTML={{ __html: this.storeContent.current_page.content.rendered}} 
                                            />
                                        }
                                    </div>
                                </div>

                                {this.state.pageblocks.map((pageblock, i) => (
                                    <React.Fragment>
                                        {(pageblock.slug === 'methode-3' || pageblock.slug === 'methode-2') &&
                                            <div 
                                                className="wrapper-content--section"  
                                                key={i} 
                                                ref={ (ref) => this.blockRef=ref } 
                                            />
                                        }
                                        {(pageblock.slug !== 'particulieren' && pageblock.slug !== 'coaches') &&
                                            <div className="wrapper-content--section">
                                                <div className="wrapper-content--text">
                                                    <h2 className="page-content__title" dangerouslySetInnerHTML={{ __html: pageblock.title.rendered}} />
                                                    <div className="page-content__text" dangerouslySetInnerHTML={{ __html: pageblock.content.rendered}} />

                                                    {(pageblock.slug !== 'meer-over-charles-ruiters' &&
                                                        pageblock.slug !== 'over-charles-ruiters-1' &&
                                                        pageblock.slug !== 'over-charles-ruiters-2') &&
                                                        <div className="form-row form-row--section form-row--buttons">
                                                            <button 
                                                                className={"btn btn--primary btn--alternative " + this.state.btn_class} 
                                                                onClick={(e) => this.gotoPage(e, 'meeting')}
                                                                dangerouslySetInnerHTML={{ __html: i18n.t("button.schedule")}} 
                                                            />
                                                        </div>
                                                    }

                                                    {(pageblock.slug === 'meer-over-charles-ruiters' || 
                                                        pageblock.slug === 'over-charles-ruiters-1' || 
                                                        pageblock.slug === 'over-charles-ruiters-2') &&
                                                        <div className="form-row form-row--section form-row--buttons">
                                                            <button className={"btn btn--primary btn--alternative " + this.state.btn_class} onClick={(e) => this.gotoPage(e, 'about')}>{i18n.t("button.about")}</button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </React.Fragment>
                                ))}
                                
                                </React.Fragment>
                            }

                            {(this.state.route === '/personal-coaching' || this.state.route === '/mentoring-voor-coaches') && 
                                <BlockReviews
                                    section = {this.state.route === '/personal-coaching'
                                        ?   'PC'
                                        :   'MC'
                                    }
                                />
                            }

                            {(this.state.route === '/kennismakingsgesprek' || this.state.route === '/meeting') && 
                                <React.Fragment>
                                    <BlockMeeting
                                        lng = {this.storeUi.app_interface.lang} 
                                    />
                                </React.Fragment>
                            } 
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(ContentPage);

