import * as apifetch from './fetch.js';

export async function getBackgrounds() {
    const urlEndpoint = 'content/getBackgrounds.php';

    return apifetch.kkFetch(urlEndpoint, 'GET');
}

export async function getContentSingle(type, id) {
    const urlAPI = process.env.REACT_APP_URL_API_WP;
    const urlEndpoint = urlAPI + type + '/' + id;

    try {
        let apiStr = urlEndpoint;

        let response = await fetch(apiStr, {
            method: 'GET'
        });
        let responseJson = await response.json();
        if (responseJson !== null) {
            let apiReturn;
            if (responseJson !== true && 'error' in responseJson && responseJson.error.length > 0 ) {
                apiReturn =  responseJson.error;
            } else {
                apiReturn =  responseJson;
            }
            return apiReturn;
        }
    } catch (error) {
        console.log('SbC fetch error page: ', error.message);
    }
}

export async function getContentAll(type) {
    const urlAPI = process.env.REACT_APP_URL_API_WP;
    const urlEndpoint = urlAPI + type + '/';

    try {
        let apiStr = urlEndpoint;

        let response = await fetch(apiStr, {
            method: 'GET'
        });
        let responseJson = await response.json();
        if (responseJson !== null) {
            let apiReturn;
            if (responseJson !== true && 'error' in responseJson && responseJson.error.length > 0 ) {
                apiReturn =  responseJson.error;
            } else {
                apiReturn =  responseJson;
            }
            return apiReturn;
        }
    } catch (error) {
        console.log('SbC fetch error pages: ', error.message);
    }
}

export async function getContentMedia(id) {
    const urlAPI = process.env.REACT_APP_URL_API_WP;
    const urlEndpoint = urlAPI + 'media' + '/' + id;

    try {
        let apiStr = urlEndpoint;

        let response = await fetch(apiStr, {
            method: 'GET'
        });
        let responseJson = await response.json();
        if (responseJson !== null) {
            let apiReturn;
            if (responseJson !== true && 'error' in responseJson && responseJson.error.length > 0 ) {
                apiReturn =  responseJson.error;
            } else {
                apiReturn =  responseJson;
            }
            return apiReturn;
        }
    } catch (error) {
        console.log('SbC fetch error medias: ', error.message);
    }
}
