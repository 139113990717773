import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';

const BlockSection = inject("stores") (
    observer (
        class BlockSection extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi; 

                this.state = {
                    block: [],
                    content: ''
                };

                this.content = {
                    'coaches': {
                        title: 'coaches'
                    },
                    'individuals': {
                        title: 'professionals'
                    }
                }
            }

            componentDidMount() {

            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.block !== this.props.block) {
                    //let content = this.props.block[0].content.rendered;
                    this.setState({
                        block: this.props.block
                    })
                }
            }

            gotoPage = () => {
                let url;
                switch (this.props.section) {
                    case 'individuals':
                        url = 'personal-coaching';
                        break;
                    case 'coaches':
                        url = 'mentoring-voor-coaches';
                        break;
                    default:

                }
                this.props.history.push(url);
            }
      
            render() {
                const blockClass = this.props.content ? " --inContent" : ''
                const activeClass = this.props.section == this.props.active ? " --active" : "" 
                
                return (
                    <div 
                        className={"block--section --link" + blockClass + activeClass}
                        onClick={(e) => this.gotoPage()}
                    >
                        {!this.props.content &&
                            <div
                                className="block--section__bg" 
                            />
                        }
                        <div className={"block--section__content block--section__content--" + this.props.section}>
                            <span 
                                className="block--section__subheader">
                                    {i18n.t(this.props.subtitle)}
                                    {this.content[this.props.section].subtitle}
                            </span>
                            <span className="block--section__header">{this.content[this.props.section].title}</span>
                            {(this.props.content && this.props.block && this.props.block.length > 0) &&                  
                                <p className="page-content__text" dangerouslySetInnerHTML={{ __html: this.props.block[0].content.rendered}} />
                            }
                        </div>
                        {/* {this.props.content &&
                            <div className={"block--section__fade bg-gradient-" + this.props.section} />
                        } */}

                        <div className="form-row form-row--section form-row--buttons --inline">
                            <button 
                                className={"btn btn--primary btn--text " + this.state.btn_class} 
                                onClick={(e) => this.gotoPage()}
                                dangerouslySetInnerHTML={{ __html: i18n.t("button.more_info")}} 
                            />
                            <div className="wrapper-icon"><div className="wrapper-icon__icon"></div></div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(BlockSection));
