import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';

const BlockCooperations = inject("stores") (
    observer (
        class BlockCooperations extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeContent = this.props.stores.storeContent; 
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.state = {
                    cooperations: []
                };
            }

            componentDidMount = async() => {
                const cooperations = await this.storeContent.getContentPages('cooperations', 100);

                console.log('SbC cooperations', cooperations)
                this.setState({
                    cooperations: cooperations
                })
            }

            componentDidUpdate() {

            }

            gotoBlog = (cooperation, slug) => {
                this.storeContent.setCurrentPage(cooperation);

                let url = "/samenwerking/" + slug;
                this.props.history.push(url);
            }

            gotoPage = () => {
                let url = '/mijn-samenwerkingen';
                this.props.history.push(url);
            }
      
            render() {
                return (
                    <div className="block--cooperations">
                        <span className="block--cooperations__subtitle">Mijn samenwerkingen</span>
                        <h1 className="block--cooperations__title">Eendracht geeft kracht</h1>
                        
                        <div className="carousel carousel--cooperations">
                            <Swiper 
                                centeredSlides = {true}
                                slidesPerView={1}
                                navigation = {true}
                                modules={[Navigation]}
                                className="contentSwiper"
                            >
                                {this.state.cooperations && this.state.cooperations.map((cooperation, i) => (
                                    <SwiperSlide key={i}>
                                        <div className="carousel--banner__slide" onClick = {(e) => this.gotoBlog(cooperation, cooperation.slug)}>
                                            <div className="carousel--banner__slide-content">
                                            <div className="list--cooperations__container-image">
                                                <div className="list--cooperations__image">
                                                    <img src={this.urlAPI + "public/images/logos/" + cooperation.Logo} />
                                                </div>
                                            </div>
                                            <div className="list--cooperations__container-content">
                                                <span className="list--cooperations__title">{cooperation.title.rendered}</span>
                                                <span 
                                                        className="list--cooperations__content"
                                                        dangerouslySetInnerHTML={{ __html: cooperation.excerpt.rendered}} 
                                                    />
                                                <span 
                                                    className = "link--read-more --link"
                                                    onClick = {(e) => this.gotoBlog(cooperation, cooperation.slug)}
                                                >
                                                    Lees verder
                                                    <div class="wrapper-icon">    
                                                        <div class="wrapper-icon__icon"></div>
                                                    </div>
                                                </span>
                                            </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>

                        {/* <ul className="block--cooperations__list block--cooperations__list --collapsed">
                        {this.state.cooperations && this.state.cooperations.map((cooperation, i) => (
                            <li 
                                className="block--cooperations__listitem --link-clean" 
                                key={i}
                                onClick = {(e) => this.gotoBlog(cooperation, cooperation.slug)}
                            >
                                
                            </li>
                        ))}
                        </ul> */}
                        
                        {this.props.view === "summaries" &&
                            <div className="form-row form-row--buttons">
                                <button 
                                    className={"btn btn--primary btn--alternative " + this.state.btn_class} 
                                    onClick={(e) => this.gotoPage(e)}
                                    dangerouslySetInnerHTML={{ __html: i18n.t("button.cooperations")}}
                                />
                            </div>
                        }
                    </div>
                )
            }
        }
    )
)

export default withRouter(BlockCooperations);
