import { makeAutoObservable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as content from '../communicator/content';
import * as pages from '../communicator/pages';
import * as menus from '../communicator/menus';

class StoreContent {
    app_interface = { 
        lang: '',
        lang_id: 0
    }  
    
    content_pages = [];
    current_page = {};
    content_blocks = [];
    content_blogs = [];
    content_cooperations = [];

    menuMain = [];

    constructor() {
        this.getContentPages('pages', 100);
        this.getContentPages('pageblocks', 100);
        this.getMenus();
        // this.getMenu(2);
    }

    setCurrentPage(data) {
        this.current_page = data;
    }

    async getContentSingle(type, id) {
        let contentSingle = {};
        try {
            const returnData = await content.getContentSingle(type, id);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    contentSingle = returnData;
                    this.current_page = returnData;
                } 
                return contentSingle;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return contentSingle;
    }

    async getContentPage(id) {
        let content = {};
        try {
            const returnData = await pages.getContentPage(id);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    content = returnData;
                    this.current_page = returnData;
                } 
                return content;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return content;
    }

    async getContentDialog(id) {
        let content = {};
        try {
            const returnData = await pages.getContentPage(id);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    content = returnData;
                } 
                return content;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return content;
    }

    async getContentPages(section, amount) {
        let content = {};
        try {
            const returnData = await pages.getContentPages(section, amount);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    content = returnData;

                    if (section === 'pages') {
                        this.content_pages = returnData;
                    }
                    if (section === 'pageblocks') {
                        this.content_blocks = returnData;
                    }
                    if (section === 'cooperations') {
                        this.content_cooperations = returnData;
                    }
                    if (section === 'blogs') {
                        this.content_blogs = returnData;
                    }
                } 
                return content;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return content;
    }

    async getContentMedia(id) {
        let returnMedia = {};

        try {
            const returnData = await content.getContentMedia(id);
            runInAction(() => { 
                returnMedia = returnData.source_url;
                return returnMedia;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return returnMedia;
    }

    async getCustomFields(id) {
        let content = {};
        try {
            const returnData = await pages.getCustomFields(id);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                } 
                return content;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return content;
    }

    async getMenus() {
        let content = {};
        try {
            const returnData = await menus.getMenus();
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    //content = returnData;
                    //this.current_page = returnData;
                } 
                return content;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return content;
    }

    async getMenu(id) {
        let menu;
        try {
            const returnData = await menus.getMenu(id);
            runInAction(() => {  
                if (returnData && returnData.length !== 0) {
                    this.menuMain = returnData.items;
                    menu = returnData.items;
                    return menu;
                } 
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error";
                console.log('SbC error api return menu')
            })
        }
        return menu;
    }
}

export default StoreContent;