export function handleScroll(pos) {
    if (pos === 'top') {
        window.scrollTo(0, 0);
    } else {
        window.scrollTo(0, pos);
    }
}

export function smoothScroll(pos) {
    if (pos === 'top') {
        window.scrollTo(0, 0);
    } else {
        window.scrollTo({
            top: pos,
            behavior: "smooth"
        });
    }
}


export function checkMatchingValues(val1, val2) {
    let matching = (val1 === val2 && val1 !== '' && val2 !== '') ? true : false;
    return matching;
}

export function contains(a, obj) {
    for (var i = 0; i < a.length; i++) {
        if (a[i] === obj) {
            return true;
        }
    }
    return false;
}