import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";

import NavigationFooter from './navigation-footer';
import BlockCopyright from '../footer/block-copyright';

const Footer = inject("stores") (
    observer (
        class Footer extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi; 
            }

            componentDidMount() {

            }

            componentDidUpdate() {

            }
      
            render() {
                //i18n.changeLanguage(this.storeUi.app_interface.lang);

                return (
                    <div className="wrapper--footer">
                        <div className="footer">
                            <div className="footer__navigation">
                                <NavigationFooter

                                />
                            </div>
                        </div>
                        <div className="footer__copyright">
                            <BlockCopyright
                                lng = {this.storeUi.app_interface.lang}
                            />
                        </div>
                    </div>
                )
            }
        }
    )
)

export default Footer;
