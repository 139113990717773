import * as apifetch from './fetch.js';

export async function sendFormContact2(form, data) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/email/sendFormContact.php';

    const postData = {
        data: data
    }

    try {
        let response = await fetch(urlEndpoint, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
    
            }),
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;
            return arrData;
        }
    } catch (error) {
        let arrData = {'data': [{error : 'An error has occurred'}]};
        return arrData;
    }
}

export async function sendFormContact(form, data) {
    const urlEndpoint = 'email/contact';

    const postData = data;

    return apifetch.kkFetch(urlEndpoint, 'POST', postData);
}

export async function sendFormMeetup(form, data) {
    const urlEndpoint = 'email/meetup';

    const postData = data;

    return apifetch.kkFetch(urlEndpoint, 'POST', postData);
}