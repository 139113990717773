import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "react-router-dom";

const BlockQuote = inject("stores") (
    observer (
        class BlockQuote extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.env = process.env.REACT_APP_ENV;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeContent = this.props.stores.storeContent; 

                this.state = {
                    //bgImg: this.urlAPI + "public/images/backgrounds/bg-personal-coaching-2.png"
                    bgImg: ""
                } 
            }

            componentDidMount = async() => {
                //let idx;
                // const pages = await this.storeContent.getContentSingle('quotes', 45);
                // const idx = pages.findIndex(page => page.slug === 'start');
                //const content = await this.storeContent.getContentPage(pages[idx].id);
                // if (this.env === 'DEV') {
                //     idx = 22;
                // } else {
                //     idx = 11;
                // }
                const idx = this.props.pageQuote;

                // const content = await this.storeContent.getContentSingle('blockquotes', idx);

                // this.setState({
                //     content: content
                // })
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.pageQuote !== this.props.pageQuote) {
                    const idx = this.props.pageQuote;
                    const bgImg = this.props.bgPageQuote;
                    const content = await this.storeContent.getContentSingle('blockquotes', idx);

                    this.setState({
                        content: content,
                        bgImg: bgImg
                    })
                }
            }
      
            render() {
                return (
                    <div className="block--quote">
                        {this.state.bgImg !== '' &&
                            <div 
                                className="block--quote__background"
                                style= {{backgroundImage:`url(${this.state.bgImg})` }}
                            />
                        }
                        <div className={"block--section__fade bg-gradient-header"} />
                        <div className="block--quote__header">
                        {this.props && this.props.match && this.props.match.path === '/meeting' 
                        ?   <span>Meet me</span>
                        :   this.state.content && this.state.content.title.rendered
                        }
                        </div>

                        {/* <div className="form-row form-row--section form-row--buttons">
                            <button 
                                className={"btn btn--primary btn--alternative " + this.state.btn_class} 
                                onClick={(e) => this.gotoPage(e)}
                                dangerouslySetInnerHTML={{ __html: i18n.t("button.schedule")}} 
                            />
                        </div> */}
                    </div>
                )
            }
        }
    )
)

export default withRouter(BlockQuote);
