import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

const BlockCopyright = inject("stores") (
    observer (
        class BlockCopyright extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi; 
            }

            componentDidMount = () => {
                //i18n.changeLanguage(this.storeUi.app_interface.lang);
            }
    
            render() {
                const copyright = '© 2022'+ ' ' + i18n.t("footer.copy")

                return (
                    <div className="footer__copyright"> 
                        <div className="copyright">
                            <ul className="copyright__list"> 
                                {/* <li className="copyright__listitem copyright__listitem--c">© 2022 {i18n.t("footer.copy")}</li>  */}
                                <li 
                                    className="copyright__listitem copyright__listitem--c"
                                    dangerouslySetInnerHTML={{ __html: copyright}}
                                />
                            </ul> 
                        </div> 
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(BlockCopyright);
