import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import ScrollerTop from '../elements/scroller-top';

import Header from '../components/grid/header';
import Footer from '../components/grid/footer';
import BlockContact from '../components/blocks/block-contact';
import BlockSocial from '../components/blocks/block-social';

const Template = inject("stores") (
    observer (
        class Template extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi; 

                this.state = {}
            }

            componentDidMount() {
                const route = this.props.history.location.pathname;
                this.setState({
                    route: route
                })
            }

            componentDidUpdate(prevProps) {
                if (prevProps.location.pathname !== this.props.location.pathname) {
                    const route = this.props.history.location.pathname;
                    this.setState({
                        route: route
                    })
                }
            }
      
            render() {
                return (
                    <div className="wrapper--page">
                        <div className="wrapper--header">
                            <div className="header">
                                <div 
                                    className="wrapper--header__block-bg --left">
                                </div>
                                <div 
                                    className="wrapper--header__block-bg --right">
                                </div>
                                <div 
                                    className="wrapper--header__block-bg wrapper--header__block-bg--left">
                                </div> 
                                <div 
                                    className="wrapper--header__block-bg wrapper--header__block-bg--right">
                                </div> 
                            </div>
                            <Header />
                        </div>
                        
                        {this.props.children}

                        {(this.state.route !== '/kennismakingsgesprek' && this.state.route !== '/meeting') &&
                            <BlockContact
                                lng = {this.storeUi.app_interface.lang}
                            />
                        }

                        <BlockSocial

                        />

                        <Footer 
                            lng = {this.storeUi.app_interface.lang}
                        />

                        <ScrollerTop />
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(Template));
