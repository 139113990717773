import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";

import BlockSection from '../blocks/block-section';

const NavigationSections = inject("stores") (
    observer (
        class NavigationSections extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.env = process.env.REACT_APP_ENV;
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent; 

                this.state = {};
            }

            componentDidMount = async() => {
                const pageBlocks = this.props.pageblocks;
                const pageBlockCoaches = pageBlocks
                    .filter((block) => block.slug == 'coaches');

                const pageBlockIndividuals = pageBlocks
                    .filter((block) => block.slug == 'particulieren');

                this.setState({
                    pageBlockCoaches: pageBlockCoaches,
                    pageBlockIndividuals: pageBlockIndividuals
                })
            }

            componentDidUpdate = async(prevProps) => {
                if (this.props.pageblocks.length !== 0 && prevProps.pageblocks.length === 0) {
                    const pageBlocks = this.props.pageblocks;
                    const pageBlockCoaches = pageBlocks
                        .filter((block) => block.slug == 'coaches');

                    const pageBlockIndividuals = pageBlocks
                        .filter((block) => block.slug == 'particulieren');

                    this.setState({
                        pageBlockCoaches: pageBlockCoaches,
                        pageBlockIndividuals: pageBlockIndividuals
                    })
                }
            }

            handleClickNav = (slug) => {
                const url = '/' + slug;
                this.props.history.push(url);
            }
      
            render() {
                return (
                    <React.Fragment>
                        <BlockSection
                            section = {"coaches"}
                            block = {this.state.pageBlockCoaches}
                            content = {this.props.content}
                            active = {this.props.active}
                            subtitle = {"subheaders.mentoring_for"}
                            lng = {this.storeUi.app_interface.lang}
                        />

                        <BlockSection
                            section = {"individuals"}
                            block = {this.state.pageBlockIndividuals}
                            content = {this.props.content}
                            active = {this.props.active}
                            subtitle = {"subheaders.coaching_for"}
                            lng = {this.storeUi.app_interface.lang}
                        />
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(NavigationSections);
