import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import BlockAddress from '../footer/block-address';

const NavigationFooter = inject("stores") (
    observer (
        class NavigationFooter extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.env = process.env.REACT_APP_ENV;
                this.storeUi = this.props.stores.storeUi; 
                this.storeContent = this.props.stores.storeContent; 

                this.state = {
                    menu: []
                };
            }

            componentDidMount = async() => {
                let idx;
                if (this.env === 'DEV') {
                    //idx = 3;
                    idx = 5;
                } else {
                    idx = 5;
                }

                const menu = await this.storeContent.getMenu(idx);
                this.setState({
                    menu: menu
                })
            }

            componentDidUpdate = async() => {

            }

            handleClickNav = (slug) => {
                const url = '/' + slug;
                this.props.history.push(url);
            }
      
            render() {
                return (
                    <div className="wrapper--navigation-footer">
                        <div className="wrapper--navigation-footer__row">
                            <div className="wrapper--navigation-footer__column">
                                <ul>
                                    {this.state.menu && this.state.menu.map((item, i) => (
                                        <li 
                                            className="--link" 
                                            onClick={() => this.handleClickNav(item.object_slug)}
                                            key={i}
                                        >
                                            <Link to={"/" + item.object_slug}>{item.title}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div className="wrapper--navigation-footer__column">
                                <ul className="copyright__list"> 
                                    <li className="--link"><a href="https://www.charlesruiters.com/wp-content/uploads/2020/01/Algemene_voorwaarden_ChR-CT_januari_2020.pdf" target="_blank" rel="noopener">{i18n.t("navigation.general_terms")}</a></li> 
                                    <li className="--link"><a href="https://www.charlesruiters.com/wp-content/uploads/2021/01/PRIVACYVERKLARING-CRCT-Jan-2021.pdf" target="_blank" rel="noopener">{i18n.t("navigation.privacy")}</a></li> 
                                    <li className="--link"><a href="https://www.charlesruiters.com/wp-content/uploads/2021/05/ICF-Core-Competencies-Mei-2021-CRCT.pdf" target="_blank" rel="noopener">{i18n.t("navigation.icf_core")}</a></li> 
                                    <li className="--link"><a href="https://www.charlesruiters.com/wp-content/uploads/2021/01/De-Code-van-Ethiek-2020-ICF.pdf" target="_blank" rel="noopener">{i18n.t("navigation.icf_ethics")}</a></li> 
                                </ul> 
                            </div>
                        </div>

                        <div className="wrapper--navigation-footer__row">                        
                            <div className="wrapper--navigation-footer__column">
                                <BlockAddress 
                                    lng = {this.storeUi.app_interface.lang}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(NavigationFooter));
